<template>
  <span class="loader"></span>
</template>

<script>
export default {
  name: 'LoadingState'
};
</script>

<style scoped>
.loader {
      margin: 40px auto;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      position: relative;
      animation: rotate 0.7s linear infinite;
    }
    .loader::before {
      content: "";
      box-sizing: border-box;
      position: absolute;
      inset: 0px;
      border-radius: 50%;
      border: 5px solid #0072bc;
      animation: prixClipFix 1.4s linear infinite alternate-reverse;
    }
    @keyframes rotate {
      100%   {transform: rotate(360deg)}
    }
    @keyframes prixClipFix {
        0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
        25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
        50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
        75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
        100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
    }
</style>