<template>
  <modal :showModal="showModal" @close-modal="dismissSuccessfully" :contentClass='"w-50"'>
    <template v-slot:header>
      <div class='d-flex justify-content-between'>
        <h4>Edit Scenario</h4>        
      </div>
    </template>

    <template v-slot:main-content>
      <FormulateForm @submit="submitScenarioUpdate">
        <div>
          <FormulateInput
            name="scenario name"
            label="Scenario Name"
            type="text"
            v-model='newScenarioName'
            validation="required"
            element-class='mt-0'
            input-class='border' />
          <span class="details-note mt-1">Use a descriptive name to easily identify the scenario.</span> 
        </div>
        <hr>
        <div class="submit-container">
          <custom-button theme='ghost-button' type="button" @click="dismissSuccessfully">Cancel</custom-button>
          <div class="submit-button-container">
            <div class="loading-spinner-container"><loading-state class="loading-spinner" v-if="updatingScenario" /></div>
            <div id='scenario-creation-button'>
              <FormulateInput
                name="Update scenario name"
                type="submit"
                :error="errorUpdatingScenario ? 'Error updating scenario' : null"
                /> 
              <b-tooltip 
                target='scenario-creation-button' 
                triggers='hover'
                v-if='!newScenarioName.trim()'>
                Scenario name cannot be blank.
              </b-tooltip>
            </div>
          </div>
        </div>
        <b-alert show variant=warning class="mt-2 p-1" v-if="duplicatedScenarioName">
          <b-icon-exclamation-triangle-fill class="mr-2"/>
          <span>Scenario name already exists.</span>
        </b-alert>        
      </FormulateForm>
    </template>
  </modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import LoadingState from '@/components/views/LoadingState';
import { Modal, CustomButton } from 'rwdi-widgets';
import { GET_LOGGED_IN_USER } from '@/store/actions/auth';

export default {
  name: 'EditScenarioModal',
  components: {
    Modal,
    LoadingState,
    CustomButton
  },
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    simulationId: {
      type: Number,
      required: false
    },
    configurationId: {
      type: Number,
      required: false
    },
    scenarioName: {
      type: String,
      required: false
    }
  },
  computed: {    
    project() {
      let project;
      project = this.$store.getters['project/selectedProject'];
      if (project) {
        return project;
      } else {
        let allProjects = this.$store.getters['project/allProjects'];
        project = allProjects.find(proj => proj.id === this.projectId);
        return project;
      }
    },
    canCreateScenario() {
      return (this.allowedPermissions || {});
    },
    ...mapGetters(['allowedPermissions', 'loggedInUser']),
    ...mapGetters('project', ['allProjects', 'uploadUuid', 'jobTypes', 'criteria']),
  },
  data() {
    return {
      modalIsVisible: false,
      newScenarioName: this.scenarioName,
      errorUpdatingScenario: false,
      duplicatedScenarioName: false,
      updatingScenario: false
    };
  },
  async created() {
    await this.$store.dispatch(GET_LOGGED_IN_USER);
  },
  methods: {
    addTooltip(element, tooltip) {
      let tooltipSpan = document.createElement('span');
      tooltipSpan.classList.add('criteria-tooltip');
      let tooltipText = document.createTextNode(tooltip);
      tooltipSpan.appendChild(tooltipText);
      element.appendChild(tooltipSpan);
      element.addEventListener('mouseover', this.showCriteriaTooltip);
      element.addEventListener('mouseout', this.hideCriteriaTooltip);
    },
    removeTooltip(element) {
      let tooltipSpan = element.querySelector('.criteria-tooltip');
      element.removeChild(tooltipSpan);
      element.removeEventListener('mouseover', this.showCriteriaTooltip);
      element.removeEventListener('mouseout', this.hideCriteriaTooltip);
    },
    async submitScenarioUpdate(data) {
      let scenarioCreationInfo = null;      
      scenarioCreationInfo = await this.updateScenario(data);
      if (scenarioCreationInfo === null) {
        this.dismissSuccessfully();
      }
    },
    async updateScenario() {
      try {
        this.updatingScenario = true;
        await this.storeUpdateScenario({
          projectId: Number(this.$route.params.id),
          studyId: Number(this.$route.params.study),
          configurationId: this.configurationId,
          scenario: {
            name: this.newScenarioName,
            simulation_id: this.simulationId
          }
        });
        this.updatingScenario = false;
        this.dismissSuccessfully();
        this.$router.go();
        return {};
      } catch (error) {
        return null;
      }
    },
    dismissSuccessfully() {
      this.$emit('close-modal');
    },    
    ...mapActions({
      storeUpdateScenario: 'project/updateScenario',
    })
  },
};
</script>

<style scoped>
.radio-button-disabled {
  opacity: 0.5;
}

.edit-scenario-modal {
  overflow-y: auto;
}

.submit-container {
  display: flex;
}

.submit-container > * {
  margin-top: 0;
}

.loading-spinner {
  height: 1.25em;
  width: 1.25em;
  padding: 0.5em 1.5em!important;
}

.loading-view {
  display: flex;
  align-items: center;
}

.formulate-input {
  margin-bottom: 0;
}

.small-text {
  font-size: 0.625rem;
}

.small-label {
  font-size: .9em;
}
</style>

<style>
.criteria-tooltip {
   display: none;
   background-color: rgb(103, 102, 102);
   color: rgb(255, 255, 255);
   font-size: 0.75rem;
   position: absolute;
   margin-top: -3.5rem;
   margin-left: 0.5rem;
   padding-left: 0.35rem;
   padding-right: 0.35rem;
   border-radius: 0.35rem;
}

[id^=checkbox-disabled] {
  opacity: 0.5;
}

.subscription-radios .formulate-input-element input[type=radio]:checked~.formulate-input-element-decorator:before {
  background-color: #007bff !important;
}

.level-radios .formulate-input-element input[type=radio]:checked~.formulate-input-element-decorator:before {
  background-color: #007bff !important;
}

.formulate-input .formulate-input-element {
  max-width: none;
}

.formulate-input[data-classification=button] button {
  background-color: var(--primary-blue);
  border: 0.063rem solid var(--primary-blue-dark);
  transition: all .2s ease-in-out;
}

.formulate-input[data-classification=button] button:active {
  background-color: var(--primary-blue);
  border: 0.063rem solid var(--primary-blue-dark);
  transition: all .2s ease-in-out;
}

.formulate-input[data-classification=file] .formulate-input-upload-area input:focus~.formulate-input-upload-area-mask, .formulate-input[data-classification=file] .formulate-input-upload-area input:hover~.formulate-input-upload-area-mask, .formulate-input[data-classification=file] .formulate-input-upload-area input[data-is-drag-hover]~.formulate-input-upload-area-mask {
  border-color: var(--primary-blue);
  color: var(--primary-blue);
}

.formulate-input[data-classification=file] .formulate-input-upload-area input:focus~.formulate-input-upload-area-mask:before, .formulate-input[data-classification=file] .formulate-input-upload-area input:hover~.formulate-input-upload-area-mask:before, .formulate-input[data-classification=file] .formulate-input-upload-area input[data-is-drag-hover]~.formulate-input-upload-area-mask:before {
  border-color: var(--primary-blue);
  color: var(--primary-blue);
  background-color: var(--primary-blue);
}

.formulate-input[data-classification=file] .formulate-files .formulate-file-add {
  border-color: var(--primary-blue);
  color: var(--primary-blue);
}


.submit-container {
  display: flex;
  margin-top: 0;
  justify-content: space-between;
  align-items: center;
}

.submit-container > * {
  margin-top: 0;
}

.submit-button-container {
  display: flex;
}

.submit-button-container > * {
  margin: 0;
}

.loading-spinner {
  height: 1.25em;
  width: 1.25em;
  padding: 0.5em 1.5em!important;
}

.loading-view {
  display: flex;
  align-items: center;
}

.details-note {
    color: #6d6d6d;
    font-size: .7em;
    font-weight: 400;
    line-height: 1.5
}

hr {
  height: 0.125rem;
  margin-bottom: 1.563rem;
  margin-top: 1.563rem;
}
</style>
